import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
  Typography,
  useTheme,
  styled,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberInfo } from '../../utils/commons';
import { client } from '../../client';
import { ClientEvents } from '../../constants/events-const';
import { CallAction, CallStatus } from '../../constants/commons-const';
import { DisconnectCallDirect, setCallDirectStatus, setPeer } from '../../redux/slices/callDirect';
import SimplePeer from 'simple-peer';
import MemberAvatar from '../../components/MemberAvatar';
import {
  Microphone,
  MicrophoneSlash,
  Phone,
  PhoneDisconnect,
  Screencast,
  VideoCamera,
  VideoCameraSlash,
  X,
} from 'phosphor-react';
import { Howl } from 'howler';

const ICE_SERVERS = [
  { urls: 'stun:stun.l.google.com:19302' },
  { urls: 'stun:global.stun.twilio.com:3478' },
  {
    urls: 'turn:36.50.62.152:3478',
    username: 'hoang',
    credential: 'pass1',
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledCallDirectDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '500px',
      height: '550px',
      background: theme.palette.mode === 'light' ? '#F0F4FA' : theme.palette.background.paper,
      '& .receiverAvatar': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '70px',
      },

      '& .MuiDialogActions-root': {
        padding: '15px',
        position: 'relative',
        zIndex: 1,
        '& >:not(:first-of-type)': {
          marginLeft: '20px',
        },
      },
    },
  },
}));

const StyledButton = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  width: '70px',
  '& .MuiButton-root': {
    minWidth: '45px',
    height: '45px',
    borderRadius: '50%',
    padding: 0,
    color: '#fff',
    boxShadow: 'none',
    '&.moreButton': {
      backgroundColor: theme.palette.grey[300],
      '&:hover, &.active': {
        backgroundColor: theme.palette.grey[600],
      },
    },
  },
  ' & .spanTitle': {
    fontSize: '12px',
    display: 'block',
    color: theme.palette.grey[500],
  },
}));

const CallDirectDialog = ({ open }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const localStreamRef = useRef(null);
  // const remoteStreamRef = useRef(null);
  const localVideoRef = useRef(null);
  const localAudioRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const intervalRef = useRef(null);
  const healthCallTimeoutRef = useRef(null);
  const endCallTimeoutRef = useRef(null);
  const missCallTimeoutRef = useRef(null);
  const healthCallOneSecondIntervalRef = useRef(null);
  const healthCallTenSecondIntervalRef = useRef(null);
  const ringtone = useRef();
  const { peer, callDirectStatus } = useSelector(state => state.callDirect);
  const { callerId, receiverId, cid, is_video, action } = useSelector(state => state.callDirect.callDirectData);
  const { all_members } = useSelector(state => state.member);
  const { user_id } = useSelector(state => state.auth);

  const [localStream, setLocalStream] = useState(null);
  const [callerInfo, setCallerInfo] = useState(null);
  const [receiverInfo, setReceiverInfo] = useState(null);
  const [micOn, setMicOn] = useState(true);
  const [isScreenShare, setIsScreenShare] = useState(false);
  const [time, setTime] = useState(0);
  const [loadingButton, setLoadingButton] = useState(false);
  const [signalOffer, setSignalOffer] = useState(null);
  const [signalIce, setSignalIce] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState('');
  const [errorText, setErrorText] = useState('');

  const [localCameraOn, setLocalCameraOn] = useState(false);
  const [remoteCameraOn, setRemoteCameraOn] = useState(false);

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setTime(prevTime => prevTime + 1);
    }, 1000);
  };

  const sendSignalData = async payload => {
    try {
      return await client.startCall(payload);
    } catch (error) {
      dispatch(setCallDirectStatus(CallStatus.ERROR));
      if (error.response.data.ermis_code === 20) {
        setErrorText('Receiver busy');
        throw new Error('Receiver busy');
      } else {
        setErrorText('Call Failed');
      }
    }
  };

  const onSendCreateCall = async () => {
    const payload = {
      action,
      cid,
      is_video,
    };
    const response = await sendSignalData(payload);

    if (response) {
      dispatch(setCallDirectStatus(CallStatus.CONNECTING));

      // Thiết lập timeout sau 60 giây sẽ gửi miss-call
      missCallTimeoutRef.current = setTimeout(() => {
        onSendMissCall();
      }, 60000);
    }
  };

  const onSendHealthCall = async () => {
    const payload = {
      action: CallAction.HEALTH_CALL,
      cid,
    };
    await sendSignalData(payload);
  };

  const onSendUpgradeCall = async () => {
    const payload = {
      action: CallAction.UPGRADE_CALL,
      cid,
    };
    await sendSignalData(payload);
  };

  const onSendMissCall = async () => {
    const payload = {
      action: CallAction.MISS_CALL,
      cid,
    };
    const response = await sendSignalData(payload);
    if (response) {
      onCancelCall();
    }
  };

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const startRing = () => {
    ringtone.current = new Howl({
      src: ['/ringtone.mp3'],
      loop: true,
    });
    ringtone.current.play();
  };

  const stopRing = () => {
    if (ringtone.current) {
      ringtone.current.stop();
    }
  };

  useEffect(() => {
    if (callerId && receiverId) {
      setCallerInfo(getMemberInfo(callerId, all_members));
      setReceiverInfo(getMemberInfo(receiverId, all_members));
    }
  }, [callerId, receiverId, all_members]);

  useEffect(() => {
    if ([CallStatus.CONNECTING, CallStatus.RINGING].includes(callDirectStatus)) {
      startRing();
    } else {
      stopRing();
    }

    return () => {
      stopRing();
    };
  }, [callDirectStatus]);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
      setLocalStream(stream);
      // if (localStreamRef.current) {
      //   localStreamRef.current.srcObject = stream;
      // }

      // Hiển thị local video nếu có track video
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack && localVideoRef.current) {
        const videoStream = new MediaStream([videoTrack]);
        localVideoRef.current.srcObject = videoStream;
      }

      // // Hiển thị local audio nếu có track audio
      const audioTrack = stream.getAudioTracks()[0];
      if (audioTrack && localAudioRef.current) {
        const audioStream = new MediaStream([audioTrack]);
        localAudioRef.current.srcObject = audioStream;
      }

      // if (!is_video) {
      //   stream.getTracks().forEach(track => track.stop());
      //   navigator.mediaDevices.getUserMedia({ audio: true });
      // }
    });
  }, []);

  const onSetSignalIce = signal => {
    const sdp = `${signal.candidate.sdpMid}$${signal.candidate.sdpMLineIndex}$${signal.candidate.candidate}`;
    const obj = { type: 'ice', sdp };
    setSignalIce(prev => [...prev, obj]);
  };

  const onSetSignalOffer = signal => {
    setSignalOffer(signal);
  };

  const onClearRef = () => {
    clearTimeout(healthCallTimeoutRef.current);
    clearTimeout(endCallTimeoutRef.current);
    clearTimeout(missCallTimeoutRef.current);
    clearInterval(healthCallOneSecondIntervalRef.current);
    clearInterval(healthCallTenSecondIntervalRef.current);
    // localStreamRef.current = null;
    // remoteStreamRef.current = null;
    remoteVideoRef.current = null;
    remoteAudioRef.current = null;
    localVideoRef.current = null;
    localAudioRef.current = null;
  };

  // const onSwitchToVideoCall = () => {
  //   navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(newStream => {
  //     // Thay thế track hiện tại bằng track mới
  //     const videoTrack = newStream.getVideoTracks()[0]; // Lấy video track
  //     const audioTrack = newStream.getAudioTracks()[0]; // Lấy audio track

  //     console.log('------videoTrack-----', videoTrack);
  //     console.log('------audioTrack-----', audioTrack);

  //     // Thay thế từng track nếu tồn tại
  //     if (peer) {
  //       console.log('----peer.getSenders()---', peer._pc.getSenders());

  //       const sender = peer._pc.getSenders().find(s => s.track?.kind === 'audio');
  //       console.log('----sender---', sender);

  //       if (sender) sender.replaceTrack(videoTrack);

  //       // if (videoTrack) {
  //       //   const sender = peer._pc.getSenders().find(s => s.track?.kind === 'video');
  //       //   if (sender) sender.replaceTrack(videoTrack);
  //       // }
  //       // if (audioTrack) {
  //       //   const sender = peer._pc.getSenders().find(s => s.track?.kind === 'audio');
  //       //   if (sender) sender.replaceTrack(audioTrack);
  //       // }
  //     }

  //     // setLocalStream(newStream);
  //     if (localStreamRef.current) {
  //       localStreamRef.current.srcObject = newStream;
  //     }

  //     // Hiển thị local video nếu có track video
  //     if (videoTrack && localVideoRef.current) {
  //       const videoStream = new MediaStream([videoTrack]);
  //       localVideoRef.current.srcObject = videoStream;
  //     }
  //     // // Hiển thị local audio nếu có track audio
  //     if (audioTrack && localAudioRef.current) {
  //       const audioStream = new MediaStream([audioTrack]);
  //       localAudioRef.current.srcObject = audioStream;
  //     }
  //   });
  // };

  const createPeer = (initiator, stream) => {
    const peer = new SimplePeer({
      initiator,
      trickle: true,
      stream,
      config: {
        iceServers: ICE_SERVERS,
      },
      channelName: 'rtc_data_channel',
      allowHalfTrickle: true,
    });

    // stream?.getTracks().forEach(track => {
    //   peer.addTrack(track, stream);
    // });

    peer.on('connect', () => {
      const jsonData = {
        type: 'transciver_state',
        body: {
          audio_enable: true,
          video_enable: is_video,
        },
      };

      peer.send(JSON.stringify(jsonData));

      const onConnectCall = async () => {
        const payload = {
          action: CallAction.CONNECT_CALL,
          cid,
          is_video,
        };
        const response = await sendSignalData(payload);

        if (response) {
          startTimer();
          dispatch(setCallDirectStatus(CallStatus.CONNECTED));
          setLoadingButton(false);

          // Gửi health_call mỗi giây qua peer
          healthCallOneSecondIntervalRef.current = setInterval(() => {
            peer.send(JSON.stringify({ type: 'health_call' }));
          }, 1000);

          // Gửi health_call mỗi 10 giây qua server
          healthCallTenSecondIntervalRef.current = setInterval(() => {
            onSendHealthCall();
          }, 10000);

          // Clear intervals khi component bị hủy
          return () => {
            clearInterval(healthCallOneSecondIntervalRef.current);
            clearInterval(healthCallTenSecondIntervalRef.current);
          };
        }
      };
      onConnectCall();
    });

    peer.on('data', data => {
      const message = JSON.parse(data);

      if (message.type === 'transciver_state') {
        const videoEnable = message.body.video_enable;
        setRemoteCameraOn(videoEnable);
      }

      if (message.type === 'health_call') {
        // Xóa các timeout hiện tại nếu nhận được health_call
        clearTimeout(healthCallTimeoutRef.current);
        clearTimeout(endCallTimeoutRef.current);

        // Đặt timeout để kiểm tra nếu không nhận health_call trong 3 giây
        healthCallTimeoutRef.current = setTimeout(() => {
          setConnectionStatus(
            `${user_id === callerInfo?.id ? receiverInfo?.name : callerInfo?.name} network connection is unstable`,
          );
        }, 3000);

        // Đặt timeout để kiểm tra nếu không nhận health_call trong 30 giây
        endCallTimeoutRef.current = setTimeout(() => {
          onSendEndCall();
        }, 30000);

        setConnectionStatus('');
      }
    });

    peer.on('track', (track, remoteStream) => {
      console.log('---track---', track);
      console.log('---remoteStream---', remoteStream);

      // if (remoteStreamRef.current) {
      //   remoteStreamRef.current.srcObject = remoteStream; // Cập nhật video của đối tác
      // }

      if (track.kind === 'video' && remoteVideoRef.current) {
        const videoStream = new MediaStream([track]);
        remoteVideoRef.current.srcObject = videoStream; // Thêm video stream
      } else if (track.kind === 'audio' && remoteAudioRef.current) {
        const audioStream = new MediaStream([track]);
        remoteAudioRef.current.srcObject = audioStream; // Thêm audio stream
      }
    });

    peer.on('close', () => {
      onSendEndCall();
    });

    return peer;
  };

  useEffect(() => {
    setLocalCameraOn(is_video);
    setRemoteCameraOn(is_video);

    if (callDirectStatus === CallStatus.IDLE && localStream) {
      const peerCaller = createPeer(true, localStream);
      dispatch(setPeer(peerCaller));

      peerCaller.on('signal', signal => {
        if (signal.type === 'candidate') {
          onSetSignalIce(signal);
        } else {
          onSetSignalOffer(signal);
        }
      });

      onSendCreateCall();
    }
  }, [dispatch, callDirectStatus, is_video, localStream]);

  useEffect(() => {
    const handleCall = event => {
      if (event.action === CallAction.ACCEPT_CALL) {
        const onSendAcceptCall = async () => {
          const payload = {
            action: CallAction.SIGNAL_CALL,
            cid: event.cid,
            is_video: event.is_video,
            signal: signalOffer,
          };
          await sendSignalData(payload);
        };

        onSendAcceptCall();
      }

      if (event.action === CallAction.SIGNAL_CALL) {
        if (event.signal.type === 'answer') {
          peer.signal(event.signal);

          const sendAllCandidates = async () => {
            if (signalIce.length > 0) {
              await Promise.all(
                signalIce.map(candidate =>
                  sendSignalData({
                    action: CallAction.SIGNAL_CALL,
                    cid: event.cid,
                    is_video: event.is_video,
                    signal: candidate,
                  }),
                ),
              );
            }
          };

          sendAllCandidates();
        } else if (event.signal.type === 'ice') {
          const splitSdp = event.signal.sdp.split('$');
          const signal = {
            candidate: {
              candidate: splitSdp[2],
              sdpMLineIndex: Number(splitSdp[1]),
              sdpMid: splitSdp[0],
            },
            type: 'candidate',
          };

          peer.signal(signal);
        } else if (event.signal.type === 'offer') {
          const peerReceiver = createPeer(false, localStream);

          dispatch(setPeer(peerReceiver));

          peerReceiver.on('signal', signal => {
            let newSignal;
            if (signal.type === 'candidate') {
              const sdp = `${signal.candidate.sdpMid}$${signal.candidate.sdpMLineIndex}$${signal.candidate.candidate}`;
              newSignal = { type: 'ice', sdp };
            } else {
              newSignal = signal;
            }

            const onSendSignalCall = async () => {
              const payload = {
                action: CallAction.SIGNAL_CALL,
                cid: event.cid,
                is_video: event.is_video,
                signal: newSignal,
              };
              await sendSignalData(payload);
            };

            onSendSignalCall();
          });
          peerReceiver.signal(event.signal);
        }
      }

      if ([CallAction.END_CALL, CallAction.REJECT_CALL, CallAction.MISS_CALL].includes(event.action)) {
        onCancelCall();
      }
    };

    const handleConnectionChanged = event => {
      if (!event.online) {
        setConnectionStatus('Your network connection is unstable');
      } else {
        setConnectionStatus('');
      }
    };

    client.on(ClientEvents.Signal, handleCall);
    client.on(ClientEvents.ConnectionChanged, handleConnectionChanged);
    return () => {
      client.off(ClientEvents.Signal, handleCall);
      client.off(ClientEvents.ConnectionChanged, handleConnectionChanged);
    };
  }, [dispatch, client, signalOffer, signalIce, peer, cid, is_video, localStream]);

  const onSendAcceptCall = async () => {
    const payload = {
      cid,
      action: CallAction.ACCEPT_CALL,
      is_video,
    };
    setLoadingButton(true);
    const response = await sendSignalData(payload);
    if (response) {
      stopRing();
    }
  };

  const onSendEndCall = async () => {
    const payload = {
      cid,
      action: CallAction.END_CALL,
    };
    const response = await sendSignalData(payload);

    if (response) {
      onCancelCall();
    }
  };

  const onSendRejectCall = async () => {
    const payload = {
      cid,
      action: CallAction.REJECT_CALL,
    };
    const response = await sendSignalData(payload);

    if (response) {
      onCancelCall();
    }
  };

  const onCancelCall = () => {
    if (peer) {
      peer.destroy();
    }
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
    }
    dispatch(DisconnectCallDirect());
    onClearRef();
  };

  const onToggleMic = () => {
    if (localStream) {
      localStream.getAudioTracks()[0].enabled = !micOn;
      setMicOn(!micOn);
    }
  };

  const onToggleCamera = () => {
    // onSwitchToVideoCall();

    if (localStream) {
      localStream.getVideoTracks()[0].enabled = !localCameraOn;

      setLocalCameraOn(!localCameraOn);

      const jsonData = {
        type: 'transciver_state',
        body: {
          audio_enable: true,
          video_enable: !localCameraOn,
        },
      };
      peer.send(JSON.stringify(jsonData));

      if (!is_video) {
        onSendUpgradeCall();
      }
    }
  };

  const onScreenShare = () => {
    if (isScreenShare) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
        const screenTrack = stream.getTracks()[0];
        peer.replaceTrack(peer.streams[0].getVideoTracks()[0], screenTrack, peer.streams[0]);
        setIsScreenShare(false);

        setLocalStream(stream);
        // if (localStreamRef.current) {
        //   localStreamRef.current.srcObject = stream;
        // }

        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack && localVideoRef.current) {
          const videoStream = new MediaStream([videoTrack]);
          localVideoRef.current.srcObject = videoStream;
        }
      });
    } else {
      navigator.mediaDevices.getDisplayMedia({ video: true }).then(stream => {
        const screenTrack = stream.getTracks()[0];
        peer.replaceTrack(peer.streams[0].getVideoTracks()[0], screenTrack, peer.streams[0]);
        setIsScreenShare(true);

        setLocalStream(stream);
        // if (localStreamRef.current) {
        //   localStreamRef.current.srcObject = stream;
        // }

        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack && localVideoRef.current) {
          const videoStream = new MediaStream([videoTrack]);
          localVideoRef.current.srcObject = videoStream;
        }

        screenTrack.onended = () => {
          onScreenShare();
        };
      });
    }
  };

  const renderButton = () => {
    return (
      <>
        <StyledButton>
          <Button
            className={`moreButton ${micOn && callDirectStatus === CallStatus.CONNECTED ? 'active' : ''}`}
            variant="contained"
            color="inherit"
            onClick={onToggleMic}
            disabled={callDirectStatus !== CallStatus.CONNECTED}
          >
            {micOn ? <Microphone size={20} weight="fill" /> : <MicrophoneSlash size={20} weight="fill" />}
          </Button>
          <span className="spanTitle">{micOn ? 'mute' : 'unmute'}</span>
        </StyledButton>
        <StyledButton>
          <Button
            className={`moreButton ${localCameraOn && callDirectStatus === CallStatus.CONNECTED ? 'active' : ''}`}
            variant="contained"
            color="inherit"
            onClick={onToggleCamera}
            disabled={callDirectStatus !== CallStatus.CONNECTED}
          >
            {localCameraOn ? <VideoCamera weight="fill" size={20} /> : <VideoCameraSlash weight="fill" size={20} />}
          </Button>
          <span className="spanTitle">{localCameraOn ? 'stop video' : 'start video'}</span>
        </StyledButton>

        {localCameraOn && (
          <StyledButton>
            <Button
              className={`moreButton ${isScreenShare && callDirectStatus === CallStatus.CONNECTED ? 'active' : ''}`}
              variant="contained"
              color="inherit"
              onClick={onScreenShare}
              disabled={callDirectStatus !== CallStatus.CONNECTED}
            >
              <Screencast weight="fill" size={20} />
            </Button>
            <span className="spanTitle">screencast</span>
          </StyledButton>
        )}

        {callDirectStatus === CallStatus.RINGING && (
          <>
            <StyledButton>
              <Button onClick={onSendRejectCall} variant="contained" color="error">
                <PhoneDisconnect weight="fill" size={20} />
              </Button>
              <span className="spanTitle">decline</span>
            </StyledButton>
            <StyledButton>
              <LoadingButton onClick={onSendAcceptCall} variant="contained" color="success" loading={loadingButton}>
                <Phone weight="fill" size={20} />
              </LoadingButton>
              <span className="spanTitle">accept</span>
            </StyledButton>
          </>
        )}

        {[CallStatus.IDLE, CallStatus.CONNECTING, CallStatus.CONNECTED].includes(callDirectStatus) && (
          <StyledButton>
            <Button onClick={onCancelCall} variant="contained" color="error">
              <PhoneDisconnect weight="fill" size={20} />
            </Button>
            <span className="spanTitle">end call</span>
          </StyledButton>
        )}

        {callDirectStatus === CallStatus.ERROR && (
          <StyledButton>
            <Button onClick={onCancelCall} variant="contained" color="error">
              <X weight="fill" size={20} />
            </Button>
            <span className="spanTitle">cancel</span>
          </StyledButton>
        )}
      </>
    );
  };

  return (
    <>
      <StyledCallDirectDialog open={open} TransitionComponent={Transition} keepMounted>
        <DialogContent sx={{ padding: 0 }}>
          <Typography variant="body1" sx={{ fontSize: '22px', fontWeight: 600, margin: '15px', textAlign: 'center' }}>
            {user_id === callerInfo?.id ? receiverInfo?.name : callerInfo?.name}
          </Typography>

          {callDirectStatus === CallStatus.CONNECTED && (
            <Typography
              variant="body1"
              sx={{
                fontSize: '20px',
                fontWeight: 500,
                margin: '0 15px',
                textAlign: 'center',
                position: 'relative',
                zIndex: 3,
                color: theme.palette.success.main,
              }}
            >
              {formatTime(time)}
            </Typography>
          )}

          <div className="receiverAvatar">
            <MemberAvatar member={user_id === callerInfo?.id ? receiverInfo : callerInfo} width={200} height={200} />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 600,
              marginTop: '15px',
              fontSize: '14px',
              color: theme.palette.text.secondary,
            }}
          >
            {callDirectStatus === CallStatus.IDLE ? (
              'Waiting'
            ) : callDirectStatus === CallStatus.CONNECTING ? (
              'Connecting'
            ) : callDirectStatus === CallStatus.RINGING ? (
              'is calling you'
            ) : callDirectStatus === CallStatus.CONNECTED ? (
              <span style={{ color: theme.palette.success.main }}>Connected</span>
            ) : (
              <span style={{ color: theme.palette.error.main }}>{errorText}</span>
            )}
            {[CallStatus.IDLE, CallStatus.CONNECTING, CallStatus.RINGING].includes(callDirectStatus) && (
              <>
                &nbsp;&nbsp;
                <div className="loader">
                  <div className="dot" style={{ backgroundColor: theme.palette.text.secondary }} />
                  <div className="dot" style={{ backgroundColor: theme.palette.text.secondary }} />
                  <div className="dot" style={{ backgroundColor: theme.palette.text.secondary }} />
                </div>
              </>
            )}
          </div>

          {connectionStatus && (
            <span
              style={{
                color: '#DFE3E8',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 2,
                fontSize: '14px',
                width: '100%',
                padding: '0 15px',
                textAlign: 'center',
              }}
            >
              {connectionStatus}
            </span>
          )}

          <Stack
            sx={{
              width: '150px',
              height: '100px',
              position: 'absolute',
              bottom: '90px',
              right: '15px',
              borderRadius: '6px',
              overflow: 'hidden',
              zIndex: 2,
              // visibility: localCameraOn ? 'visible' : 'hidden',
            }}
          >
            <video
              ref={localVideoRef}
              playsInline
              autoPlay
              style={{ width: '100%', height: '100%', objectFit: 'cover', display: localCameraOn ? 'block' : 'none' }}
              muted
            />
            <audio ref={localAudioRef} autoPlay muted />
          </Stack>
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
              // visibility: remoteCameraOn ? 'visible' : 'hidden',
            }}
          >
            <video
              ref={remoteVideoRef}
              playsInline
              autoPlay
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                display: remoteCameraOn ? 'block' : 'none',
              }}
            />
            <audio ref={remoteAudioRef} autoPlay />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>{renderButton()}</DialogActions>
      </StyledCallDirectDialog>
    </>
  );
};

export default CallDirectDialog;
